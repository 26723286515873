<template>
  <div id="app" class="securityposture">
    <el-row :gutter="4" class="elRow" style="display: flex">
      <el-col :span="3">
        <el-select
          style="width: 100%"
          v-model="querayObj.reason"
          size="small"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in xqing1"
            :key="item.valuecx"
            :label="$t(`SecurityMonitor.alarmMessage.types.${item.label1}`)"
            style="text-algin: center; padding-left: 21px"
            :value="item.valuecx"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          size="small"
          v-model="querayObj.level"
          filterable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in xqing2"
            :key="item.valuecx2"
            style="padding-left: 21px"
            :label="
              $t(`SecurityMonitor.alarmMessage.BehaviorLevel.${item.label1}`)
            "
            :value="item.valuecx2"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          size="small"
          v-model="querayObj.name"
          filterable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in xqing3"
            :key="item.valuecx3"
            style="padding-left: 21px"
            :label="item.label1"
            :value="item.valuecx3"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-cascader
          size="small"
          style="width: 100%"
          :options="city"
          :props="props"
          collapse-tags
          v-model="querayObj.selectedOptions"
          :separator="' '"
          clearable
          placeholder="位置"
          class="placeInput"
          filterable
        ></el-cascader>
      </el-col>
      <el-col :span="3">
        <el-button size="small" type="primary" @click="onSubmit">{{
          $t("public.Inquire")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="4" class="table elRow">
      <div
        style="
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 5px;
          top: -32px;
          cursor: pointer;
          z-index: 22;
        "
      >
        <!-- 弹出框 -->
        <el-popover
          placement="bottom"
          trigger="click"
          popper-class="LinkageTable"
        >
          <el-checkbox-group
            @change="tableTitleTime"
            v-model="checkList"
            style="display: flex; flex-direction: column"
          >
            <el-checkbox
              v-for="(item, index) in tradeSelections"
              :key="index"
              :label="
                $t(
                  `SecurityMonitor.alarmMessage.alarmList.PopupBox.${item.eng}`
                )
              "
              :checked="item.isChecked"
              :disabled="item.flag"
              @click.native="sss(item.eng, index, item.flag)"
              >{{
                $t(
                  `SecurityMonitor.alarmMessage.alarmList.PopupBox.${item.name}`
                )
              }}</el-checkbox
            >
          </el-checkbox-group>
          <img
            slot="reference"
            src="../../assets/aa.png"
            alt=""
            style="width: 20px; cursor: pointer"
          />
        </el-popover>
      </div>
      <!--列表-->
      <!--列表-->
      <el-table
        size="mini"
        ref="myTable"
        stripe
        fit
        :data="
          lists.slice(
            (querayObj.currentPage - 1) * querayObj.pageSize,
            querayObj.currentPage * querayObj.pageSize
          )
        "
        tooltip-effect="dark"
        class="tableClass"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <template>
          <el-table-column
            v-if="$filter.nameFilter(formThead, tradeSelections)"
          >
            <el-table-column
              width="120"
              :show-overflow-tooltip="true"
              v-for="(item, index) in formThead"
              :key="index"
              :label="item.name"
            >
              <template slot-scope="scope">
                {{ scope.row[item.eng] }}
              </template>
            </el-table-column>
          </el-table-column>
        </template>
        >
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.DeviceName')"
          v-if="tradeSelections[tradeSelections.length - 19].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <!-- 类型 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.alarmMessage.alarmList.Typeof')"
          v-if="tradeSelections[tradeSelections.length - 18].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <!-- 威胁类型 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.BehaviorType')"
          v-if="tradeSelections[tradeSelections.length - 17].isChecked"
        >
          <!-- <template slot-scope="scope">
                {{ scope.row.actiontype || "威胁类型" }}
              </template> -->
          <template v-slot="{ row }">
            <template v-if="row.actiontype == 'S_CHECK_LOCATION_FRAUD'"
              ><span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.LocationFraud")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DOMAIN'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.DomainNameFraud")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_INJECT_STATUS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.InjectionAttack")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DEBUG_STATUS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.DebuggingBehavior")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_MEMORY_CHANGE'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.MemoryTampering")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_PLUGIN'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.ProgramPlugIn")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SIGNATURE'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.ApplicationCracking")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_ABNORMAL_CA'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.HttpsHijacking")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_WIFI_PROXY'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.HttpProxy")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_MULTI_APK'">
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.ApplicationMultiplex"
                )
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FRAME_ATTACK'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.FrameAttack")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_ACCOUNT'">
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.HighFrequencyChangeAccount"
                )
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_IP'">
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.HighFrequencyChangeIP"
                )
              }}</span>
            </template>
            <template
              v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_LOCATION'"
            >
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.HighFrequencyChangePosition"
                )
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_RESTART'">
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.HighFrequencyStartingApplication"
                )
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DANGER_APPS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.RiskApplication")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_ROOT_STATUS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.ROOTJailbreak")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_FRAME_ATTACH'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.FrameSoftware")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_ADB_ENABLED'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.USBDebugging")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_VM_STATUS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.simulator")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_ALLOW_MOCK_LOCATION'">
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.SimulatedPositionAllowed"
                )
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SYS_USER_CA'">
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.RootCertificateException"
                )
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_CUSTOM_ROM'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.CustomROM")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SCREENSHOT_STATUS'">
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.TheScreenshotBlockIsRemovedProcedure"
                )
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SYSTEM_TIME'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.SystemTimeException")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SCREEN_PASSWORD'">
              <span>{{
                $t(
                  "SecurityMonitor.alarmMessage.alarmList.TheScreenLockPasswordPsNotEnabled"
                )
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_VPN_STATUS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.VPN")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_MACOS_STATUS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.macOS")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_WINDOWS_STATUS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.Windows")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CLOUD_PHONE_STATUS'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.CloudPhone")
              }}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DANGER_IP'">
              <span>{{
                $t("SecurityMonitor.alarmMessage.alarmList.RiskIP")
              }}</span>
            </template>
            <template v-else-if="row.actiontype">
              <span>{{ row.actiontype }}</span>
            </template>
          </template>
        </el-table-column>
        <!-- 行为级别 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.Behaviorlevel')"
          v-if="tradeSelections[tradeSelections.length - 16].isChecked"
        >
          <template slot-scope="scope">
            <span
              style="color: #d9001b; cursor: auto"
              v-if="scope.row.level == '3'"
              >{{ $t("SecurityMonitor.alarmMessage.alarmList.high") }}</span
            >
            <span
              style="color: #f59a23; cursor: auto"
              v-if="scope.row.level == '2'"
              >{{ $t("SecurityMonitor.alarmMessage.alarmList.medium") }}</span
            >
            <span
              style="color: #00cdcd; cursor: auto"
              v-if="scope.row.level == '1'"
              >{{ $t("SecurityMonitor.alarmMessage.alarmList.low") }}</span
            >
          </template>
        </el-table-column>
        <!-- 应用名称  -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.ApplicationName')"
          v-if="tradeSelections[tradeSelections.length - 15].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <!-- 应用版本 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.ApplicationVersion')"
          v-if="tradeSelections[tradeSelections.length - 14].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.version }}
          </template>
        </el-table-column>
        <!-- 系统版本 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.SystemVersion')"
          v-if="tradeSelections[tradeSelections.length - 13].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.osVersion }}
          </template>
        </el-table-column>
        <!-- 位置 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.Position')"
          v-if="tradeSelections[tradeSelections.length - 12].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.location }}
          </template>
        </el-table-column>

        <!-- 静伟度 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.LatitudeLongitude')"
          v-if="tradeSelections[tradeSelections.length - 11].isChecked"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.longitude == '' || scope.row.latitude == ''"
            ></span
            ><span class="detaildesc" v-else
              >{{ scope.row.latitude }},{{ scope.row.longitude }}</span
            >
          </template>
        </el-table-column>
        <!-- 上网方式 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.InternetMethod')"
          v-if="tradeSelections[tradeSelections.length - 10].isChecked"
        >
          <template slot-scope="scope">
            {{ $filter.surfInterType(scope.row.netType) }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.ExternalIP')"
          v-if="tradeSelections[tradeSelections.length - 9].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.outIp }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.IntranetIP')"
          v-if="tradeSelections[tradeSelections.length - 8].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.ip }}
          </template>
        </el-table-column>
        <!-- WIFI-SSID -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.alarmMessage.alarmList.WIFISSID')"
          v-if="tradeSelections[tradeSelections.length - 7].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.wifiSsid }}
          </template>
        </el-table-column>
        <!-- WIFI-MAC -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('SecurityMonitor.alarmMessage.alarmList.WIFIMAC')"
          v-if="tradeSelections[tradeSelections.length - 6].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.wifiMac }}
          </template>
        </el-table-column>
        <!-- 用户名 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.Username')"
          v-if="tradeSelections[tradeSelections.length - 5].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <!-- 姓名 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.Name')"
          v-if="tradeSelections[tradeSelections.length - 4].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.username }}
          </template>
        </el-table-column>
        <!-- 部门 -->
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          :label="$t('public.Section')"
          v-if="tradeSelections[tradeSelections.length - 3].isChecked"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">{{
              row.groupfullname.substr(groupFullNameShowByIndex)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('public.GenerationTime')"
          v-if="tradeSelections[tradeSelections.length - 2].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          :show-overflow-tooltip="true"
          :label="$t('public.ReportingTime')"
          v-if="tradeSelections[tradeSelections.length - 1].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.createtime | dateformat }}
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :page="querayObj.currentPage"
        :limit="querayObj.pageSize"
        :total="querayObj.total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      />
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import "echarts/map/js/china";
import * as echarts from "echarts";
import { regionData } from "element-china-area-data"; //引入
import pagination from "@/components/pagination/page";
import optionscity from "@/utils/country-level3-data"; //三级城市数据

function randomData() {
  return Math.round(Math.random() * 500);
}
export default {
  // components: { pagrtion },
  name: "App",
  // 组件
  components: {
    pagination,
  },
  data() {
    return {
      tradeSelections: [
        { eng: "devicename", name: "设备名称", isChecked: true, flag: false },
        { eng: "type", name: "类型", isChecked: true, flag: true },
        { eng: "actiontype", name: "威胁类型", isChecked: true, flag: true },
        { eng: "level", name: "行为级别", isChecked: true, flag: false },
        { eng: "name", name: "应用名称", isChecked: true, flag: true },
        { eng: "version", name: "应用版本", isChecked: true, flag: false },
        { eng: "osVersion", name: "系统版本", isChecked: false, flag: false },
        { eng: "location", name: "位置", isChecked: true, flag: false },
        {
          eng: "longitudeAndLatitude",
          name: "经纬度",
          isChecked: false,
          flag: false,
        },
        { eng: "netType", name: "上网方式", isChecked: false, flag: false },
        { eng: "outIp", name: "外网IP", isChecked: true, flag: false },
        { eng: "ip", name: "内网IP", isChecked: false, flag: false },
        { eng: "wifiSsid", name: "WIFI-SSID", isChecked: false, flag: false },
        { eng: "wifiMac", name: "WIFI-MAC", isChecked: false, flag: false },
        { eng: "loginname", name: "用户名", isChecked: false, flag: false },
        { eng: "username", name: "姓名", isChecked: false, flag: false },
        { eng: "groupfullname", name: "部门", isChecked: true, flag: false },
        { eng: "createTime", name: "产生时间", isChecked: false, flag: false },
        { eng: "time", name: "上报时间", isChecked: true, flag: true },
      ],
      groupFullNameShowByIndex: "", //用户部门截取的下标

      active: "使用时长", //选中样式
      active1: "移动应用", //选种样式
      number: 0,
      // 数量
      appnum: 0,
      // 开启多选
      props: { multiple: true },
      // 城市
      city: optionscity,
      checkList: [], //选中的选项eng数组
      lists: [],
      list1: ["移动应用", "威胁类型"],
      count: [],
      value: "all",
      props: { multiple: true },
      number: 0, // 数量
      appnum: 0, // 开启多选
      active: "使用时长", //选中样式
      xqing1: [
        {
          valuecx: "类型",
          label1: "类型",
        },
        {
          valuecx: "威胁行为",
          label1: "威胁行为",
        },
        {
          valuecx: "安全事件",
          label1: "安全事件",
        },
      ],
      xqing2: [
        {
          valuecx2: "0",
          label1: "行为级别",
        },
        {
          valuecx2: "3",
          label1: "高",
        },
        {
          valuecx2: "2",
          label1: "中",
        },
        {
          valuecx2: "1",
          label1: "低",
        },
      ],
      xqing3: [],
      xqing4: [
        {
          valuecx4: "0",
          label1: "位置",
        },
      ],
      betime: 7,
      value1: "0",
      options: [],
      active1: "移动应用", //选种样式
      reqType: "useTime",

      valuecx: "0",
      valuecx2: "0",
      valuecx3: "0",
      valuecx4: "0",
      reqType2: "topSum",
      dictotol: 0,
      cityarrs: [],
      checkList: [], //选中的选项eng数组
      querayObj: {
        // 分页
        pageSize: 10,
        currentPage: 1,
        reason: "类型",
        level: "0",
        name: "应用名称",
        selectedOptions: "位置",
        total: 0, //总条数
      },
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头

      tableData: [],
      appRunSum: "", // 应用运行次数
      querayObj1: {
        type: "1",
      },

      deviceActiveSum: "", // 应用活跃总数
      tradeSelections: [
        { eng: "devicename", name: "设备名称", isChecked: true, flag: false },
        { eng: "type", name: "类型", isChecked: true, flag: true },
        { eng: "actiontype", name: "威胁类型", isChecked: true, flag: true },
        { eng: "level", name: "行为级别", isChecked: true, flag: false },
        { eng: "name", name: "应用名称", isChecked: true, flag: true },
        { eng: "version", name: "应用版本", isChecked: true, flag: false },
        { eng: "osVersion", name: "系统版本", isChecked: false, flag: false },
        { eng: "location", name: "位置", isChecked: true, flag: false },
        {
          eng: "longitudeAndLatitude",
          name: "经纬度",
          isChecked: false,
          flag: false,
        },
        { eng: "netType", name: "上网方式", isChecked: false, flag: false },
        { eng: "outIp", name: "外网IP", isChecked: true, flag: false },
        { eng: "ip", name: "内网IP", isChecked: false, flag: false },
        { eng: "wifiSsid", name: "WIFI-SSID", isChecked: false, flag: false },
        { eng: "wifiMac", name: "WIFI-MAC", isChecked: false, flag: false },
        { eng: "loginname", name: "用户名", isChecked: false, flag: false },
        { eng: "username", name: "姓名", isChecked: false, flag: false },
        { eng: "groupfullname", name: "部门", isChecked: true, flag: false },
        { eng: "createTime", name: "产生时间", isChecked: false, flag: false },
        { eng: "time", name: "上报时间", isChecked: true, flag: true },
      ],
      groupFullNameShowByIndex: "", //用户部门截取的下标
    };
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },

  created() {
    if (this.$route.query.cityName) {
      let cityArray = this.$route.query.cityName.split(",");
      let options = ["北京", "天津", "上海", "重庆"];
      options.forEach((i, index) => {
        if (cityArray.length == 1) {
          if (cityArray[0] == i) {
            this.city.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, item.value, itemss.value]);
                  });
                });
              }
            });
            this.$set(this.querayObj, "selectedOptions", this.cityarrs);
          } else {
            this.city.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, items.value, itemss.value]);
                  });
                });
              }
            });
            this.$set(this.querayObj, "selectedOptions", this.cityarrs);
          }
        } else {
          if (cityArray[0] == i) {
            this.city.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    if (itemss.value == cityArray[1]) {
                      this.cityarrs.push([
                        item.value,
                        item.value,
                        itemss.value,
                      ]);
                    }
                  });
                });
              }
            });
            this.$set(this.querayObj, "selectedOptions", this.cityarrs);
          } else {
            this.city.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  if (items.value == cityArray[1]) {
                    items.children.forEach((itemss, indexss) => {
                      this.cityarrs.push([
                        item.value,
                        items.value,
                        itemss.value,
                      ]);
                    });
                  }
                });
              }
            });
            this.$set(this.querayObj, "selectedOptions", this.cityarrs);
          }
        }
      });
    }
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.loadTabel();
    this.updated();
    // ---------------------------
  },
  methods: {
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    updated() {
      // myTable是表格的ref属性值
      if (this.$refs.myTable && this.$refs.myTable.doLayout) {
        this.$refs.myTable.doLayout();
      }
    },
    // 分页
    handleParentGetList(page, limit) {
      console.log(page, limit);
      this.querayObj.currentPage = page; //点击  的页数
      this.querayObj.pageSize = limit;
    },
    async loadTabel() {
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getRealTimeAlarm",
        this.querayObj,
        true
      );
      this.lists = res.data;
      this.querayObj.total = this.lists.length;
      console.log(this.lists, this.querayObj.total, "this.lists");
      // //数据取数
      // 1 ---- res.data.splice(0, 10)   (0, 20)
      // 当前页： d  2   2
      // 显示条数： n 10   20
      //  res.data.splice(n*(d-1)+1,n*d)  d=1  当前页
      // 当前页 d>1 res.data.splice  (n*d-n+1),n*d)

      let dataOnedict = [];
      this.lists.forEach((item) => {
        dataOnedict.push({
          valuecx3: item.name,
          label1: item.name,
        });
      });
      let obj = {};
      let peon = dataOnedict.reduce((cur, next) => {
        obj[next.label1] ? "" : (obj[next.label1] = true && cur.push(next));
        return cur;
      }, []);
      this.xqing3 = peon;
      this.xqing3.unshift({
        valuecx3: "应用名称",
        label1: "应用名称",
      });
      if (this.querayObj.reason == "类型") {
        let data = this.lists.filter((item) => {
          if (item.type.indexOf(this.querayObj.reason) == -1) {
            return item;
          }
        });
        this.lists = res.data;
        this.querayObj.total = this.lists.length;
        // 对于为空的字段，显示N/A
        for (var i = 0; i < this.lists.length; i++) {
          var obj2 = this.lists[i];
          for (var key in obj2) {
            if (obj2[key] == null || obj2[key] == "") {
              obj2[key] = "N/A";
            }
          }
        }
      } else {
        let data = this.lists.filter((item) => {
          if (item.type.indexOf(this.querayObj.reason) !== -1) {
            return item;
          }
        });
        this.lists = data;
        this.querayObj.total = this.lists.length;
        // 对于为空的字段，显示N/A
        for (var i = 0; i < this.lists.length; i++) {
          var obj2 = this.lists[i];
          for (var key in obj2) {
            if (obj2[key] == null || obj2[key] == "") {
              obj2[key] = "N/A";
            }
          }
        }
      }
      if (this.querayObj.level == "0") {
        let data = this.lists.filter((item) => {
          if (item.level.toString().indexOf(this.querayObj.level) == -1) {
            return item;
          }
        });
        this.lists = data;
        this.querayObj.total = this.lists.length;
        // 对于为空的字段，显示N/A
        for (var i = 0; i < this.lists.length; i++) {
          var obj2 = this.lists[i];
          for (var key in obj2) {
            if (obj2[key] == null || obj2[key] == "") {
              obj2[key] = "N/A";
            }
          }
        }
      } else {
        let data = this.lists.filter((item) => {
          if (item.level.toString().indexOf(this.querayObj.level) !== -1) {
            return item;
          }
        });
        this.lists = data;
        this.querayObj.total = this.lists.length;
        // 对于为空的字段，显示N/A
        for (var i = 0; i < this.lists.length; i++) {
          var obj2 = this.lists[i];
          for (var key in obj2) {
            if (obj2[key] == null || obj2[key] == "") {
              obj2[key] = "N/A";
            }
          }
        }
      }
      if (this.querayObj.name == "应用名称") {
        let data = this.lists.filter((item) => {
          if (item.name.indexOf(this.querayObj.name) == -1) {
            return item;
          }
        });

        this.lists = data;
        this.querayObj.total = this.lists.length;
        // 对于为空的字段，显示N/A
        for (var i = 0; i < this.lists.length; i++) {
          var obj2 = this.lists[i];
          for (var key in obj2) {
            if (obj2[key] == null || obj2[key] == "") {
              obj2[key] = "N/A";
            }
          }
        }
      } else {
        let data = this.lists.filter((item) => {
          if (item.name.indexOf(this.querayObj.name) !== -1) {
            return item;
          }
        });

        this.lists = data;
        this.querayObj.total = this.lists.length;
        // 对于为空的字段，显示N/A
        for (var i = 0; i < this.lists.length; i++) {
          var obj2 = this.lists[i];
          for (var key in obj2) {
            if (obj2[key] == null || obj2[key] == "") {
              obj2[key] = "N/A";
            }
          }
        }
      }
      if (
        this.querayObj.selectedOptions == "位置" ||
        this.querayObj.selectedOptions == null
      ) {
        this.querayObj.selectedOptions = ["位置"];
        let selectedOptions = this.querayObj.selectedOptions.flat().join("/");
        let data = this.lists.filter((item) => {
          if (item.location.indexOf(selectedOptions) == -1) {
            return item;
          }
        });
        this.lists = data;
        this.querayObj.total = this.lists.length;
        // 对于为空的字段，显示N/A
        for (var i = 0; i < this.lists.length; i++) {
          var obj2 = this.lists[i];
          for (var key in obj2) {
            if (obj2[key] == null || obj2[key] == "") {
              obj2[key] = "N/A";
            }
          }
        }
      } else {
        var a = [];

        if (this.querayObj.selectedOptions.length === 0) {
          this.querayObj.selectedOptions = ["位置"];
        } else {
          this.querayObj.selectedOptions.forEach((item) => {
            a.push(item ? item.flat().join("/") : "");
          });
          var dataSHU = [];
          for (var i = 0; i < this.lists.length; i++) {
            for (var j = 0; j < a.length; j++) {
              if (this.lists[i].location == a[j]) {
                dataSHU.push(this.lists[i]);
              }
            }
          }
          this.lists = dataSHU;

          this.querayObj.total = this.lists.length;
        }
        // 对于为空的字段，显示N/A
        for (var i = 0; i < this.lists.length; i++) {
          var obj2 = this.lists[i];
          for (var key in obj2) {
            if (obj2[key] == null || obj2[key] == "") {
              obj2[key] = "N/A";
            }
          }
        }
      }
    },
    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "devicename" ||
          index == "type" ||
          index == "actiontype" ||
          index == "level" ||
          index == "name" ||
          index === "version" ||
          index == "osVersion" ||
          index == "location" ||
          index == "longitudeAndLatitude" ||
          index == "netType" ||
          index == "outIp" ||
          index == "ip" ||
          index == "wifiSsid" ||
          index == "wifiMac" ||
          index == "loginname" ||
          index == "username" ||
          index == "groupfullname" ||
          index == "time" ||
          index == "createTime"
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked;
        }
        this.number = 0;
      } else {
        this.number = 1;
      }
    },

    async onSubmit() {
      //查询
      this.querayObj.currentPage = 1;
      // 这是拿到列表的方法
      this.loadTabel();
    },

    tableTitleTime(event) {}, //配置项监听事件
  },
};
</script>

<style lang="scss" scoped>
.securityposture {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.show {
  width: 100%;
  height: 100%;
  background: white;

  .title {
    width: 100%;
  }
}

// 头
.grid-content {
  width: 100%;
  padding-top: 10px;
  height: 120px;
  display: flex;
  justify-content: space-between;

  .box {
    width: 19%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    background: #ffffff;

    .iimg {
      line-height: 126px;
    }

    .right {
      line-height: 30px;

      .right1 {
        margin-top: 15px;
      }
    }
  }
}

.wgxw {
  color: blue;
}

.top {
  width: 1500px;
  height: 130px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  background: rgba(247, 245, 245, 0.938);
}

.el-table {
  font-size: 12px !important;
}
.el-select-dropdown__list {
  text-align: center;
}
// 样式哦切换
.active {
  // background: #fd7522;
  border-bottom: 3px solid rgb(30, 137, 229) !important;
  color: black;
}

.top1 {
  width: 300px;
  height: 100px;
  border: solid 1px black;
  background: white;
  display: flex;
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
}

.center {
  width: 100%;
  // height: 840px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  .left-c {
    width: 33%;
    box-sizing: border-box;

    height: 600px;

    .topleft-c {
      width: 100%;
      height: 295px;
      background: white;
      position: relative;
    }

    .bottomleft-c {
      width: 100%;
      height: 295px;
      margin-top: 10px;
      background: white;
    }
  }

  .center-c {
    width: 33%;
    box-sizing: border-box;
    height: 600px;
    background: white;

    .content-c {
      width: 100%;
      height: 600px;
      // position: relative;

      .zhongj {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 0px;
        // background: chartreuse;

        .zjleft {
          width: 150px;
          z-index: 9999;
          margin-top: 20px;
        }

        .zjcenter {
          width: 200px;
          margin-top: 10px;
          text-align: center;
          z-index: 9999;
          margin-top: 35px;
        }

        .zjright {
          width: 150px;
          z-index: 9999;
          margin-top: 20px;
        }
      }

      .zhongx {
        width: 100%;
        height: 600px;
      }
    }
  }

  .right_c {
    width: 33%;
    box-sizing: border-box;
    height: 600px;
    display: flex;
    padding-right: 20px;
    flex-direction: column;

    .topright-c {
      position: relative;
      width: 100%;
      height: 300px;
      background: white;
    }

    .bottomright-c {
      width: 100%;
      height: 300px;
      margin-top: 10px;
      background: white;
    }
  }

  .tabletrue {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.el-select-dropdown__list {
  padding-left: 21px;
}

.day:hover {
  font-size: 20px;
}

.mounth:hover {
  font-size: 20px;
}
.placeInput {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-cascader__tags .el-cascader__search-input::placeholder {
    color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep
    .el-cascader__tags
    .el-cascader__search-input::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-cascader__tags .el-cascader__search-input::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-cascader__tags .el-cascader__search-input:-ms-input-placeholder {
    color: #606266 !important;
  }
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-input--small .el-input__inner {
  height: 34px;
  line-height: 34px;
}
</style>
